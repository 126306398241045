// import Code from "@editorjs/code";
import Header from "@editorjs/header";
import Raw from "@editorjs/raw";
//import CheckList from "@editorjs/checklist";
//import Code from "@editorjs/code";
//import Delimiter from "@editorjs/delimiter";
import Embed from "@editorjs/embed";
//import Image from "@editorjs/image";
//import InlineCode from "@editorjs/inline-code";
//import LinkTool from "@editorjs/link";
import List from "@editorjs/list";
//import Marker from "@editorjs/marker";
//import Quote from "@editorjs/quote";
//import Table from "@editorjs/table";
//import Warning from "@editorjs/warning";

export const EDITOR_TOOLS = {
  // code: Code,
  header: Header,
  raw: Raw,
  embed: Embed,
  //table: Table,
  // list: List,
  //warning: Warning,
  //code: Code,
  list: {
    class: List,
    inlineToolbar: true,
    config: {
      defaultStyle: "unordered",
    },
  },
  //linkTool: LinkTool,
  //image: Image,
  //quote: Quote,
  //marker: Marker,
  //checklist: CheckList,
  //delimiter: Delimiter,
  //inlineCode: InlineCode
};
