import type { FC } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import {
  Button,
  Card,
  CardContent,
  Stack,
  Typography,
  Alert
} from '@mui/material';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import { thunks } from 'src/thunks/admin/product';
import { useDispatch } from 'src/store';
import { useMounted } from 'src/hooks/use-mounted';
import { RouterLink } from 'src/components/router-link';
import { useTranslation } from 'react-i18next';
import { tokens } from 'src/locales/tokens';
import { createRoute } from 'react-router-url-params';
import LoadingButton from '@mui/lab/LoadingButton';
import { Link, Module } from 'src/types/admin/module';
import React, { useCallback, useState } from 'react';
import Editor from 'src/components/editor/Editor';
import { OutputData } from '@editorjs/editorjs';
import EditorJsRenderer from 'src/components/editor/EditorJsRenderer';
import { Product } from 'src/types/admin/product';

interface Values {
  content: string,
  submit: null;
}

interface ProductContentEditFormProps {
  product: Product;
}

export const ProductContentEditForm: FC<ProductContentEditFormProps> = (props) => {
  const { t } = useTranslation();
  const isMounted = useMounted();
  const router = useRouter();
  const dispatch = useDispatch();
  const { product } = props;

  const getJson = useCallback(() => {
    if (product.content) {
      try {
        return JSON.parse(product.content);
      }
      catch (e) {
        return undefined;
      }
    }
    return undefined;
  }, [product.content]);

  const [data, setData] = useState<OutputData>(getJson());


  const { i18n } = useTranslation();

  const initialValues: Values = {
    content: product.content,
    submit: null
  };

  return (
    <Formik
      enableReinitialize={true}
      validateOnChange={false}
      initialValues={initialValues}
      onSubmit={async (values, helpers): Promise<void> => {
        debugger;
        var oldProduct = JSON.parse(JSON.stringify(product)) as Product;
        oldProduct.content = data ? JSON.stringify(data) : '';
        const updatedProduct = await dispatch(thunks.updateProduct(product.id, oldProduct));
        if (updatedProduct) {
          toast.success(t(tokens.admin.modules.details.form.successMessage));
        }
        else {
          toast.error(t(tokens.general.formError));
          helpers.setStatus({ success: false });
          helpers.setSubmitting(false);
        }
      }}>
      {(props) => (
        <>
          <Form>
            <Stack spacing={4}>
              <Stack spacing={3}>
                <Card sx={{
                  overflow: 'visible'
                }}>
                  <CardContent>
                    <Stack spacing={3}>
                      <Typography
                        variant='h6'>
                        {t(tokens.admin.products.details.form.content.editor)}
                      </Typography>
                      <Editor
                        data={data}
                        onChange={setData}
                        holder="editorjs-container"
                      />
                    </Stack>
                  </CardContent>
                </Card>
                <Card>
                  <CardContent>
                    <Stack spacing={3}>
                      <Typography
                        variant='h6'>
                        {t(tokens.admin.products.details.form.content.preview)}
                      </Typography>

                      {data && <EditorJsRenderer data={data} />}
                      {!data &&
                        <Alert
                          variant='outlined'
                          severity='info'>
                          <Typography>
                            {t(tokens.admin.products.details.form.content.noPreviewContent)}
                          </Typography>
                        </Alert>
                      }
                    </Stack>
                  </CardContent>
                </Card>
              </Stack>


              <Stack
                alignItems="center"
                direction="row"
                justifyContent="flex-end"
                spacing={1}
              >
                <LoadingButton
                  type="submit"
                  disabled={props.isSubmitting}
                  loading={props.isSubmitting}
                  variant="contained"
                >
                  {t(tokens.general.buttons.update)}
                </LoadingButton>
              </Stack>
            </Stack>
          </Form>
        </>
      )}
    </Formik>
  );
};
