export const tokens = {
  landing: {
    nav: {
      products: "landing.nav.products",
      singIn: "landing.nav.signIn",
      singUp: "landing.nav.signUp",
      privacyPolicy: "landing.nav.privacyPolicy",
      termsOfUse: "landing.nav.termsOfUse",
    },
  },
  nav: {
    dashboard: "nav.dashboard",
    host: "nav.host",
    profile: "nav.profile",
  },

  account: {
    signUp: {
      title: "account.signUp.title",
      header: "account.signUp.header",
      form: {
        firstName: "account.signUp.form.firstName",
        lastName: "account.signUp.form.lastName",
        phone: "account.signUp.form.phone",
        email: "account.signUp.form.email",
        password: "account.signUp.form.password",
        createAccountButton: "account.signUp.form.createAccountButton",
        termsOfUseLink: "account.signUp.form.termsOfUseLink",
        privacyPolicyLink: "account.signUp.form.privacyPolicyLink",
        andSeparator: "account.signUp.form.andSeparator",
        agreementText: "account.signUp.form.agreementText",
        signinLink: "account.signUp.form.signinLink",
        hasAccount: "account.signUp.form.hasAccount",
      },
    },
    signIn: {
      title: "account.signIn.title",
      header: "account.signIn.header",
      description: "account.signIn.description",
      noAccount: "account.signIn.noAccount",
      createAccountLink: "account.signIn.createAccountLink",
      form: {
        email: "account.signIn.form.email",
        password: "account.signIn.form.password",
        signInButton: "account.signIn.form.signInButton",
        signInViaGoogleButton: "account.signIn.form.signInViaGoogleButton",
        orSeparator: "account.signIn.form.orSeparator",
        forgotPasswordLink: "account.signIn.form.forgotPasswordLink",
        termsOfUseLink: "account.signIn.form.termsOfUseLink",
        privacyPolicyLink: "account.signIn.form.privacyPolicyLink",
        andSeparator: "account.signIn.form.andSeparator",
        agreementText: "account.signIn.form.agreementText",
      },
    },
    confirmSignIn: {
      title: "account.confirmSignIn.title",
      expiredToken: "account.confirmSignIn.expiredToken",
    },
    emailSent: {
      title: "account.emailSent.title",
      header: "account.emailSent.header",
      message: "account.emailSent.message",
    },
    resetPassword: {
      title: "account.resetPassword.title",
      header: "account.resetPassword.header",
      signInLink: "account.resetPassword.signInLink",
      form: {
        passwordChangedMessage:
          "account.resetPassword.form.passwordChangedMessage",
        password: "account.resetPassword.form.password",
        confirmPassword: "account.resetPassword.form.confirmPassword",
        resetButton: "account.resetPassword.form.resetButton",
        linkInvalidError: "account.resetPassword.form.linkInvalidError",
        linkInvalidOrExpiredError:
          "account.resetPassword.form.linkInvalidOrExpiredError",
      },
    },
    forgotPassword: {
      title: "account.resetPassword.title",
      header: "account.resetPassword.header",
      signInLink: "account.resetPassword.signInLink",
      form: {
        emailSentMessage: "account.resetPassword.form.emailSentMessage",
        email: "account.resetPassword.form.email",
        resetButton: "account.resetPassword.form.resetButton",
      },
    },
    popover: {
      settings: "account.popover.settings",
      impersonate: "account.popover.impersonate",
      creator: "account.popover.creator",
      organization: "account.popover.organization",
      stopImpersonation: "account.popover.stopImpersonation",
      signOut: "account.popover.signOut",
    },
    colorScheme: {
      light: "account.colorScheme.light",
      dark: "account.colorScheme.dark",
      scheme: "account.colorScheme.scheme",
    },
    profile: {
      title: "account.profile.title",
      header: "account.profile.header",
      passwordHeader: "account.profile.passwordHeader",
      accountDetailsHeader: "account.profile.accountDetailsHeader",
      accountProfileImage: "account.profile.accountProfileImage",
      tab: {
        general: "account.profile.tab.general",
      },
      form: {
        firstName: "account.profile.form.firstName",
        lastName: "account.profile.form.lastName",
        email: "account.profile.form.email",
        currentPassword: "account.profile.form.currentPassword",
        newPassword: "account.profile.form.newPassword",
        confirmPassword: "account.profile.form.confirmPassword",
        passwordUpdatedMessage: "account.profile.form.passwordUpdatedMessage",
        accountUpdatedMessage: "account.profile.form.accountUpdatedMessage",
      },
    },
    impersonate: {
      form: {
        header: "account.impersonate.header",
        searchForUser: "account.impersonate.form.searchForUser",
        searchForOrganization: "account.impersonate.form.searchForOrganization",
        buttons: {
          impersonate: "account.impersonate.form.buttons.impersonate",
        },
      },
    },
  },
  payment: {
    statuses: {
      approved: "payment.statuses.approved",
      pending: "payment.statuses.pending",
      unknown: "payment.statuses.unknown",
      declined: "payment.statuses.declined",
      refunded: "payment.statuses.refunded",
      noPayment: "payment.statuses.noPayment",
    },
    success: {
      title: "account.payment.success.title",
      header: "account.payment.success.header",
      product: "account.payment.success.product",
      email: "account.payment.success.email",
      orderReference: "account.payment.success.orderReference",
      status: "account.payment.success.status",
      messages: {
        approved: "account.payment.success.messages.approved",
        pending: "account.payment.success.messages.pending",
        declined: "account.payment.success.messages.declined",
        unknown: "account.payment.success.messages.unknown",
      },
    },
    error: {
      title: "account.payment.error.title",
      header: "account.payment.error.header",
      message: "account.payment.error.message",
      orderReference: "account.payment.error.orderReference",
    },
  },
  admin: {
    products: {
      list: {
        pageTitle: "admin.products.list.pageTitle",
        pageHeader: "admin.products.list.pageHeader",
        noProductsFound: "admin.products.list.noProductsFound",
        errorLoadingProducts: "admin.products.list.errorLoadingProducts",
        buttons: {
          add: "admin.products.list.buttons.add",
        },
        table: {
          title: "admin.products.list.table.name",
          accessMonthsCount: "admin.products.list.table.accessMonthsCount",
          usersCount: "admin.products.list.table.usersCount",
          months: "admin.products.list.table.months",
          start: "admin.products.list.table.start",
          actions: "admin.products.list.table.actions",
        },
        archiveProductDialogTitle:
          "admin.products.list.archiveProductDialogTitle",
        archiveProductDialogDescription:
          "admin.products.list.archiveProductDialogDescription",
        deleteProductDialogTitle:
          "admin.products.list.deleteProductDialogTitle",
        deleteProductDialogDescription:
          "admin.products.list.deleteProductDialogDescription",
      },
      create: {
        pageTitle: "admin.products.create.pageTitle",
        pageHeader: "admin.products.create.pageHeader",
        backButton: "admin.products.create.backButton",
        form: {
          title: "admin.products.create.form.title",
          description: "admin.products.create.form.description",
          startDate: "admin.products.create.form.startDate",
          endDate: "admin.products.create.form.endDate",
          isStandalone: "admin.products.create.form.isStandalone",
          successMessage: "admin.products.create.form.successMessage",
          backGroundImage: {
            header: "admin.products.create.form.backGroundImage.header",
            description:
              "admin.products.create.form.backGroundImage.description",
          },
        },
      },
      details: {
        pageTitle: "admin.products.details.pageTitle",
        pageHeader: "admin.products.details.pageHeader",
        backButton: "admin.products.details.backButton",
        noModulesFound: "admin.products.details.noModulesFound",
        errorLoadingModules: "admin.products.details.errorLoadingModules",
        buttons: {
          addModule: "admin.products.details.buttons.addModule",
        },
        menu: {
          preview: "admin.products.details.menu.preview",
        },
        table: {
          title: "admin.products.details.table.title",
          productPlans: "admin.products.details.table.productPlans",
          actions: "admin.products.details.table.actions",
          active: "admin.products.details.table.active",
        },
        tabs: {
          content: "admin.products.details.tabs.content",
          modules: "admin.products.details.tabs.modules",
          details: "admin.products.details.tabs.details",
          users: "admin.products.details.tabs.users",
          payments: "admin.products.details.tabs.payments",
          settings: "admin.products.details.tabs.settings",
          productPlans: "admin.products.details.tabs.productPlans",
        },
        form: {
          title: "admin.products.details.form.title",
          description: "admin.products.details.form.description",
          startDate: "admin.products.details.form.startDate",
          maxAccessMonthsCount:
            "admin.products.details.form.maxAccessMonthsCount",
          content: {
            editor: "admin.products.details.form.content.editor",
            preview: "admin.products.details.form.content.preview",
            noPreviewContent:
              "admin.products.details.form.content.noPreviewContent",
          },
          successMessage: "admin.products.details.form.successMessage",
          backGroundImage: {
            header: "admin.products.details.form.backGroundImage.header",
            description:
              "admin.products.details.form.backGroundImage.description",
          },
        },
        deleteModuleDialogTitle:
          "admin.products.details.deleteModuleDialogTitle",
        deleteModuleDialogDescription:
          "admin.products.details.deleteModuleDialogDescription",
        productPlans: {
          table: {
            name: "admin.products.details.productPlans.table.name",
            price: "admin.products.details.productPlans.table.price",
            currency: "admin.products.details.productPlans.table.currency",
            actions: "admin.products.details.productPlans.table.actions",
            noPaymentPlansFound:
              "admin.products.details.productPlans.table.noPaymentPlansFound",
            errorLoadingProductPlans:
              "admin.products.details.productPlans.table.errorLoadingProductPlans",
          },
          form: {
            createTitle: "admin.products.details.productPlans.form.createTitle",
            updateTitle: "admin.products.details.productPlans.form.updateTitle",
            settingsTitle:
              "admin.products.details.productPlans.form.settingsTitle",
            name: "admin.products.details.productPlans.form.name",
            url: "admin.products.details.productPlans.form.url",
            price: "admin.products.details.productPlans.form.price",
            params: "admin.products.details.productPlans.form.params",
            currency: "admin.products.details.productPlans.form.currency",
            successMessage:
              "admin.products.details.productPlans.form.successMessage",
          },
          buttons: {
            newProductPlan:
              "admin.products.details.productPlans.buttons.newProductPlan",
          },
          deleteProductPlanDialogTitle:
            "admin.products.details.productPlans.deleteProductPlanDialogTitle",
          deleteProductPlanDialogDescription:
            "admin.products.details.productPlans.deleteProductPlanDialogDescription",
        },
        users: {
          table: {
            name: "admin.products.details.users.table.name",
            phone: "admin.products.details.users.table.phone",
            type: "admin.products.details.users.table.type",
            manualType: "admin.products.details.users.table.manualType",
            autoType: "admin.products.details.users.table.autoType",
            date: "admin.products.details.users.table.date",
            endDate: "admin.products.details.users.table.endDate",
            paymentStatus: "admin.products.details.users.table.paymentStatus",
            productPlan: "admin.products.details.users.table.productPlan",
            actions: "admin.products.details.users.table.actions",
            noUsersFound: "admin.products.details.users.table.noUsersFound",
            errorLoadingUsers:
              "admin.products.details.users.table.errorLoadingUsers",
          },
          buttons: {
            newUser: "admin.products.details.users.buttons.newUser",
          },
          deleteUserDialogTitle:
            "admin.products.details.users.deleteUserDialogTitle",
          deleteUserDialogDescription:
            "admin.products.details.users.deleteUserDialogDescription",
          sendInvitationDialogTitle:
            "admin.products.details.users.sendInvitationDialogTitle",
          sendInvitationDialogDescription:
            "admin.products.details.users.sendInvitationDialogDescription",
          invitationSentSuccessMessage:
            "admin.products.details.users.invitationSentSuccessMessage",
          form: {
            createTitle: "admin.products.details.users.form.createTitle",
            updateTitle: "admin.products.details.users.form.updateTitle",
            firstName: "admin.products.details.users.form.firstName",
            lastName: "admin.products.details.users.form.lastName",
            phone: "admin.products.details.users.form.phone",
            productPlan: "admin.products.details.users.form.productPlan",
            email: "admin.products.details.users.form.email",
            endDate: "admin.products.details.users.form.endDate",
            successMessage: "admin.products.details.users.form.successMessage",
            errors: {
              userAlreadyExists:
                "admin.products.details.users.form.errors.userAlreadyExists",
            },
          },
        },
        payments: {
          table: {
            orderReference:
              "admin.products.details.payments.table.orderReference",
            user: "admin.products.details.payments.table.user",
            date: "admin.products.details.payments.table.date",
            amount: "admin.products.details.payments.table.amount",
            paymentStatus:
              "admin.products.details.payments.table.paymentStatus",
            productPlan: "admin.products.details.payments.table.productPlan",
            actions: "admin.products.details.payments.table.actions",
            noPaymentsFound:
              "admin.products.details.payments.table.noPaymentsFound",
            errorLoadingPayments:
              "admin.products.details.payments.table.errorLoadingPayments",
          },
        },
        settings: {
          successMessage: "admin.products.details.settings.successMessage",
          errorLoadingSettings:
            "admin.products.details.settings.errorLoadingSettings",
          wayForPay: {
            paymentButton: {
              header:
                "admin.products.details.settings.wayForPay.paymentButtonHeader",
              returnUrl:
                "admin.products.details.settings.wayForPay.redirectUrl",
              serviceUrl:
                "admin.products.details.settings.wayForPay.serviceUrl",
            },
          },
          webHooks: {
            header: "admin.products.details.settings.webHooks.header",
            form: {
              url: "admin.products.details.settings.webHooks.form.url",
              params: "admin.products.details.settings.webHooks.form.params",
              productInvitationHook:
                "admin.products.details.settings.webHooks.form.productInvitationHook",
            },
          },
          emailTemplate: {
            header: "admin.products.details.settings.emailTemplates.header",
            editProductInvitationEmail:
              "admin.products.details.settings.emailTemplates.editProductInvitationEmail",
          },
          productPlans: {
            header: "admin.products.details.settings.productPlans.header",
            form: {
              name: "admin.products.details.settings.productPlans.form.name",
              successMessage:
                "admin.products.details.settings.productPlans.form.successMessage",
            },
            noPlans: "admin.products.details.settings.productPlans.noPlans",
          },
        },
      },
    },
    modules: {
      create: {
        pageTitle: "admin.modules.create.pageTitle",
        pageHeader: "admin.modules.create.pageHeader",
        backButton: "admin.modules.create.backButton",
        form: {
          title: "admin.modules.create.form.title",
          description: "admin.modules.create.form.description",
          content: "admin.modules.create.form.content",
          active: "admin.modules.create.form.active",
          startDate: "admin.modules.create.form.startDate",
          backGroundImage: {
            header: "admin.modules.create.form.backGroundImage.header",
            description:
              "admin.modules.create.form.backGroundImage.description",
          },
          link: {
            title: "admin.modules.create.form.link.title",
            url: "admin.modules.create.form.link.url",
          },
          successMessage: "admin.modules.create.form.successMessage",
        },
        linkHeader: "admin.modules.create.linkHeader",
        noLinks: "admin.modules.create.noLinks",
      },
      details: {
        pageTitle: "admin.modules.details.pageTitle",
        pageHeader: "admin.modules.details.pageHeader",
        backButton: "admin.modules.details.backButton",
        menu: {
          preview: "admin.modules.details.menu.preview",
        },
        tabs: {
          details: "admin.modules.details.tabs.details",
          content: "admin.modules.details.tabs.content",
        },
        form: {
          title: "admin.modules.details.form.title",
          description: "admin.modules.details.form.description",
          active: "admin.modules.details.form.active",
          startDate: "admin.modules.details.form.startDate",
          productPlans: {
            header: "admin.modules.details.form.productPlans.header",
            name: "admin.modules.details.form.productPlans.name",
          },
          image: {
            header: "admin.modules.details.form.backGroundImage.header",
            description:
              "admin.modules.details.form.backGroundImage.description",
          },
          content: {
            editor: "admin.modules.details.form.content.editor",
            preview: "admin.modules.details.form.content.preview",
            noPreviewContent:
              "admin.modules.details.form.content.noPreviewContent",
          },
          link: {
            title: "admin.modules.details.form.link.title",
            url: "admin.modules.details.form.link.url",
          },
          successMessage: "admin.modules.details.form.successMessage",
        },
        linkHeader: "admin.modules.details.linkHeader",
        noLinks: "admin.modules.details.noLinks",
      },
    },
    organization: {
      details: {
        pageTitle: "admin.organization.details.pageTitle",
        pageHeader: "admin.organization.details.pageHeader",
        tabs: {
          details: "admin.organization.details.tabs.details",
          users: "admin.organization.details.tabs.users",
          settings: "admin.organization.details.tabs.settings",
        },
        form: {
          name: "admin.organization.details.form.name",
          domain: "admin.organization.details.form.domain",
          domainAlreadyUsed:
            "admin.organization.details.form.domainAlreadyUsed",
          logoImage: {
            header: "admin.organization.details.form.logoImage.header",
            description:
              "admin.organization.details.form.logoImage.description",
          },
          successMessage: "admin.organization.details.form.successMessage",
        },
        settings: {
          successMessage:
            "admin.organization.details.settings.colors.successMessage",
          errorLoadingSettings:
            "admin.organization.details.settings.colors.errorLoadingSettings",
          colors: {
            header: "admin.organization.details.settings.colors.header",
            form: {
              mainColor:
                "admin.organization.details.settings.colors.form.mainColor",
              darkColor:
                "admin.organization.details.settings.colors.form.darkColor",
              darkestColor:
                "admin.organization.details.settings.colors.form.darkestColor",
              lightColor:
                "admin.organization.details.settings.colors.form.lightColor",
              lightestColor:
                "admin.organization.details.settings.colors.form.lightestColor",
              contrastTextColor:
                "admin.organization.details.settings.colors.form.contrastTextColor",
            },
          },
          payment: {
            wayForPay: {
              header:
                "admin.organization.details.settings.payment.wayForPay.header",
              form: {
                secretKey:
                  "admin.organization.details.settings.payment.wayForPay.form.secretKey",
                merchantAccount:
                  "admin.organization.details.settings.payment.wayForPay.form.merchantAccount",
                merchantDomain:
                  "admin.organization.details.settings.payment.wayForPay.form.merchantDomain",
              },
            },
          },
          email: {
            header: "admin.organization.details.settings.email.header",
            form: {
              isEnabled:
                "admin.organization.details.settings.email.form.isEnabled",
              host: "admin.organization.details.settings.email.form.host",
              port: "admin.organization.details.settings.email.form.port",
              username:
                "admin.organization.details.settings.email.form.username",
              password:
                "admin.organization.details.settings.email.form.password",
              name: "admin.organization.details.settings.email.form.name",
              email: "admin.organization.details.settings.email.form.email",
            },
          },
          emailTemplates: {
            header: "admin.organization.details.settings.emailTemplates.header",
            signInEmail:
              "admin.organization.details.settings.emailTemplates.signInEmail",
          },
        },
        users: {
          table: {
            name: "admin.organization.details.users.table.name",
            email: "admin.organization.details.users.table.email",
            role: "admin.organization.details.users.table.role",
            actions: "admin.organization.details.users.table.actions",
            noUsersFound: "admin.organization.details.users.table.noUsersFound",
            errorLoadingUsers:
              "admin.organization.details.users.table.errorLoadingUsers",
          },
          buttons: {
            newUser: "admin.organization.details.users.buttons.newUser",
          },
          deleteUserDialogTitle:
            "admin.organization.details.users.deleteUserDialogTitle",
          deleteUserDialogDescription:
            "admin.organization.details.users.deleteUserDialogDescription",
          form: {
            createTitle: "admin.organization.details.users.form.createTitle",
            updateTitle: "admin.organization.details.users.form.updateTitle",
            firstName: "admin.organization.details.users.form.firstName",
            lastName: "admin.organization.details.users.form.lastName",
            role: "admin.organization.details.users.form.role",
            email: "admin.organization.details.users.form.email",
            updatedSuccessMessage:
              "admin.organization.details.users.form.updatedSuccessMessage",
            createdSuccessMessage:
              "admin.organization.details.users.form.createdSuccessMessage",
            errors: {
              userAlreadyExists:
                "admin.organization.details.users.form.errors.userAlreadyExists",
            },
          },
        },
      },
    },
  },
  panel: {
    products: {
      details: {
        backButton: "panel.products.details.backButton",
        menu: {
          edit: "panel.products.details.menu.edit",
        },
        startDate: "panel.products.details.startDate",
        noModulesFound: "panel.products.details.noModulesFound",
        errorModulesLoading: "panel.products.details.errorModulesLoading",
        errorProductLoading: "panel.products.details.errorProductLoading",
      },
      list: {
        pageTitle: "panel.products.list.pageTitle",
        noProductsFound: "panel.products.list.noProductsFound",
        errorProductsLoading: "panel.products.list.errorProductsLoading",
        status: {
          open: "panel.products.status.open",
          closed: "panel.products.status.closed",
        },
      },
    },
    modules: {
      details: {
        backButton: "panel.modules.details.backButton",
        linkHeader: "panel.modules.details.linkHeader",
        noLinks: "panel.modules.details.noLinks",
        menu: {
          edit: "panel.modules.details.menu.edit",
        },
        loadingError: "panel.modules.details.loadingError",
      },
    },
  },
  contact: {
    title: "contact.title",
    header: "contact.header",
    subHeader: "contact.subHeader",
    form: {
      firstName: "contact.form.firstName",
      lastName: "contact.form.lastName",
      phone: "contact.form.phone",
      email: "contact.form.email",
      message: "contact.form.message",
      sendButton: "contact.form.createAccountButton",
      termsOfUseLink: "contact.form.termsOfUseLink",
      privacyPolicyLink: "contact.form.privacyPolicyLink",
      andSeparator: "contact.form.andSeparator",
      agreementText: "contact.form.agreementText",
      successTitle: "contact.form.successTitle",
      successMessage1: "contact.form.successMessage1",
      successMessage2: "contact.form.successMessage2",
    },
  },
  profile: {
    title: "profile.title",
  },
  organization: {
    notFound: {
      title: "organization.notFound.title",
      header: "organization.notFound.header",
      message: "organization.notFound.message",
    },
    select: {
      title: "organization.select.title",
      header: "organization.select.header",
      newOrganization: "organization.select.newOrganization",
      noOrganizations: "organization.select.noOrganizations",
      errorMessage: "organization.select.errorMessage",
    },
    create: {
      title: "organization.create.title",
      header: "organization.create.header",
      form: {
        name: "organization.create.form.name",
        domain: "organization.create.form.domain",
        logoImage: {
          header: "organization.create.form.logoImage.header",
          description: "organization.create.form.logoImage.description",
        },
        domainAlreadyUsed: "organization.create.form.domainAlreadyUsed",
        successMessage: "organization.create.form.successMessage",
      },
    },
  },
  errors: {
    serverError: {
      title: "errors.serverError.title",
      header: "errors.serverError.header",
      message: "errors.serverError.message",
    },
    notFound: {
      title: "errors.notFound.title",
      header: "errors.notFound.header",
      message: "errors.notFound.message",
    },
    notAuthorized: {
      title: "errors.notAuthorized.title",
      header: "errors.notAuthorized.header",
      message: "errors.notAuthorized.message",
    },
  },
  general: {
    languageChanged: "general.languageChanged",
    language: "general.language",
    newAppVersion: {
      description: "general.newAppVersion.description",
      action: "general.newAppVersion.action",
    },
    userRoles: {
      none: "general.userRoles.none",
      admin: "general.userRoles.admin",
      user: "general.userRoles.user",
    },
    filters: {
      title: "general.filters.title",
      emptyOptions: "general.filters.emptyOptions",
    },
    formError: "formError",
    paginationRowsPerPage: "paginationRowsPerPage",
    loading: "Loading",
    validators: {
      password: {
        minLength: "general.validators.password.minLength",
      },
      passwordConfirm: {
        notMatch: "general.validators.passwordConfirm.notMatch",
      },
      policy: {
        required: "general.validators.policy.required",
      },
      user: {
        conflict: "general.validators.user.conflict",
      },
      employee: {
        conflict: "general.validators.employee.conflict",
        accountConnected: "general.validators.employee.accountConnected",
      },
      role: {
        required: "general.validators.role.required",
        min: "general.validators.role.min",
      },
      invalidEmail: "general.validators.invalidEmail",
      roleRequired: "general.validators.roleRequired",
      required: "general.validators.required",
      maxLength50: "general.validators.maxLength50",
      maxLength255: "general.validators.maxLength255",
      maxLength1000: "general.validators.maxLength1000",
      oneItemRequired: "general.validators.oneItemRequired",
    },
    filepicker: {
      titleHighlight: "general.filepicker.titleHighlight",
      titleNormal: "general.filepicker.titleNormal",
      imageExtensions: "general.filepicker.imageExtensions",
      excelExtensions: "general.filepicker.excelExtensions",
      fileSizeError: "general.filepicker.fileSizeError",
      deleteImage: "general.filepicker.deleteImage",
      imageUploaded: "general.filepicker.imageUploaded",
    },
    editor: {
      tools: {
        paragraph: "general.editor.tools.text",
        heading: "general.editor.tools.heading",
        list: "general.editor.tools.list",
        rawHtml: "general.editor.tools.rawHtml",
        link: "general.editor.tools.link",
        bold: "general.editor.tools.bold",
        italic: "general.editor.tools.italic",
      },
      blockTunes: {
        delete: "general.editor.blockTunes.delete.Delete",
        moveUp: "general.editor.blockTunes.moveUp.Move up",
        moveDown: "general.editor.blockTunes.moveDown.Move down",
      },
    },
    buttons: {
      ok: "general.buttons.ok",
      delete: "general.buttons.delete",
      edit: "general.buttons.edit",
      add: "general.buttons.add",
      copy: "general.buttons.copy",
      invite: "general.buttons.invite",
      send: "general.buttons.send",
      update: "general.buttons.update",
      archive: "general.buttons.archive",
      create: "general.buttons.create",
      cancel: "general.buttons.cancel",
      close: "general.buttons.close",
      loadMore: "general.buttons.loadMore",
      tryAgain: "general.buttons.tryAgain",
      backToHome: "general.buttons.backToHome",
      addItem: "general.buttons.addItem",
      next: "general.buttons.next",
      generateAgain: "general.buttons.generateAgain",
      generateStrategy: "general.buttons.generateStrategy",
    },
  },
};
