import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AccountModule } from 'src/types/panel/account-module';

interface ModulesState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: AccountModule[];
}

type GetModulesAction = PayloadAction<AccountModule[]>;
type ModulesLoadingStatusAction = PayloadAction<boolean>;
type ModulesErrorStatusAction = PayloadAction<boolean>;

const initialState: ModulesState = {
  isLoading: true,
  hasLoadingError: false,
  items: []
};

const reducers = {
  setLoadingStatus: (state: ModulesState, action: ModulesLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: ModulesState, action: ModulesErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getModules: (state: ModulesState, action: GetModulesAction): void => {
    state.items = action.payload;
  },
};

export const slice = createSlice({
  name: 'account-modules',
  initialState,
  reducers
});

export const { reducer } = slice;
