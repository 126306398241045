import React, { memo, useEffect, useRef } from "react";
import EditorJS, { OutputData } from "@editorjs/editorjs";
import { EDITOR_TOOLS } from "./EditorTools";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { tokens } from "src/locales/tokens";

//props
type Props = {
  data?: OutputData;
  onChange(val: OutputData): void;
  holder: string;
};

const Editor = ({ data, onChange, holder }: Props) => {
  //add a reference to editor
  const ref = useRef<EditorJS>();
  const { t } = useTranslation();

  //initialize editorjs
  useEffect(() => {
    //initialize editor if we don't have a reference
    if (!ref.current) {
      const editor = new EditorJS({
        holder: holder,
        tools: EDITOR_TOOLS as any,
        i18n: {
          messages: {
            toolNames: {
              "Text": t(tokens.general.editor.tools.paragraph) as string,
              "Heading": t(tokens.general.editor.tools.heading) as string,
              "List": t(tokens.general.editor.tools.list) as string,
              "Raw HTML": t(tokens.general.editor.tools.rawHtml) as string,
              "Link": t(tokens.general.editor.tools.link) as string,
              "Bold": t(tokens.general.editor.tools.bold) as string,
              "Italic": t(tokens.general.editor.tools.italic) as string
            },
            blockTunes: {
              "delete": {
                "Delete": t(tokens.general.editor.blockTunes.delete) as string
              },
              "moveUp": {
                "Move up": t(tokens.general.editor.blockTunes.moveUp) as string
              },
              "moveDown": {
                "Move down": t(tokens.general.editor.blockTunes.moveDown) as string
              }
            },
          }
        },
        data,
        async onChange(api, event) {
          const data = await api.saver.save();
          onChange(data);
        },
        hideToolbar: false,
      });
      ref.current = editor;
    }

    return () => {
      if (ref.current && ref.current.destroy) {
        ref.current.destroy();
      }
    };
  }, []);

  return <Box id={holder}
    sx={{
      border: "1px solid #e0e0e0",
      borderRadius: "8px",
      pt: {
        xs: 2,
        sm: 2
      },
      pb: {
        xs: 2,
        sm: 2
      },
      pl: {
        xs: 2,
        sm: 8,
      },
      pr: {
        xs: 2,
        sm: 2
      },
      "& .ce-block__content": {
        maxWidth: "100%",
      },
      "& .ce-toolbar__content": {
        maxWidth: "100%",
      },
      "& .codex-editor__redactor": {
        pb: "24px !important",
      },
      // "& .ce-block": {
      //   backgroundColor: "white",
      //   boxShadow: "0 0 5px 0 rgba(0,0,0,0.1)",
      //   borderRadius: "5px",
      //   marginBottom: "10px",
      // },
    }} />;
};

export default memo(Editor);
