import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { Module } from 'src/types/admin/module';

interface ModulesState {
  isLoading: boolean;
  hasLoadingError: boolean;
  items: Module[];
  hasOrderChanged: boolean;
}

type GetModulesAction = PayloadAction<Module[]>;
type ModulesLoadingStatusAction = PayloadAction<boolean>;
type ModulesErrorStatusAction = PayloadAction<boolean>;
type MoveModuleUpAction = PayloadAction<string>;
type EnableModuleAction = PayloadAction<string>;
type DisableModuleAction = PayloadAction<string>;
type MoveModuleDownAction = PayloadAction<string>;

const initialState: ModulesState = {
  isLoading: false,
  hasLoadingError: false,
  hasOrderChanged: false,
  items: []
};

const reducers = {
  setLoadingStatus: (state: ModulesState, action: ModulesLoadingStatusAction): void => {
    state.isLoading = action.payload;
  },
  setErrorStatus: (state: ModulesState, action: ModulesErrorStatusAction): void => {
    state.hasLoadingError = action.payload;
  },
  getModules: (state: ModulesState, action: GetModulesAction): void => {
    state.items = action.payload;
  },
  moveModuleUp: (state: ModulesState, action: MoveModuleUpAction): void => {

    const module = state.items.find(module => module.id === action.payload);
    if (!module) {
      return;
    }
    const index = state.items.indexOf(module);
    if (index === 0) {
      return;
    }
    state.items[index] = state.items[index - 1];
    state.items[index - 1] = module;
    state.hasOrderChanged = true;
  },
  enableModule: (state: ModulesState, action: EnableModuleAction): void => {

    const module = state.items.find(module => module.id === action.payload);
    if (!module) {
      return;
    }
    module.isActive = true;
  },
  disableModule: (state: ModulesState, action: DisableModuleAction): void => {
    const module = state.items.find(module => module.id === action.payload);
    if (!module) {
      return;
    }
    module.isActive = false;
  },
  moveModuleDown: (state: ModulesState, action: MoveModuleDownAction): void => {

    const module = state.items.find(module => module.id === action.payload);
    if (!module) {
      return;
    }
    const index = state.items.indexOf(module);
    if (index === state.items.length - 1) {
      return;
    }
    state.items[index] = state.items[index + 1];
    state.items[index + 1] = module;
    state.hasOrderChanged = true;
  }
};

export const slice = createSlice({
  name: 'admin-modules',
  initialState,
  reducers
});

export const { reducer } = slice;
